
/**
 * @name: 业务合作方管理-业务合作方信息管理
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 业务合作方管理-业务合作方信息管理
 * @update: 2024-07-18 14:45
 */
import { Vue, Component } from "vue-property-decorator"
import {
  cooperatingPageApi,
  cooperatingModifyApi,
  cooperatingDetailApi,
  cooperatingCreateApi,
  cooperatingAgentPageApi,
  cooperatingDevicePageApi
} from '@/apis/cooperating/list'
import {
  ICooperating,
  ICooperatingParams
} from '@/apis/cooperating/list/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy } from '@/utils/common'
import { checkPhone } from '@/constants/validators'

@Component({})
export default class cooperatingList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ICooperating[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ICooperatingParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<ICooperating> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    viewTitle: '业务合作方详情',
    addTitle: '新增业务合作方',
    editTitle: '编辑业务合作方',
    labelWidth: '120px',
    column: [
      {
        "label": "业务合作方编号",
        "prop": "id",
        "align": "left",
        "width": 180,
        "slot": true,
        addHide: true,
        editHide: true
      },
      {
        label: '业务合作方',
        prop: 'partnerKeywords',
        placeholder: '业务合作方编号/名称',
        hide: true,
        editHide: true,
        addHide: true,
        viewHide: true,
        search: true
      },
      {
        "label": "业务合作方名称",
        "prop": "partnerName",
        "align": "center",
        maxlength: 20,
        rules: [
          {
            required: true,
            message: '业务合作方名称不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, cb: Function) => {
              if (!/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(value)) {
                cb(new Error('业务合作方名称只能输入中文、字母、数字'))
                return
              }
              cb()
            },
            trigger: 'blur'
          }
        ],
        span: 12
      },
      {
        label: '负责人',
        prop: 'personInChargeKeywords',
        placeholder: '负责人/手机号',
        hide: true,
        editHide: true,
        addHide: true,
        viewHide: true,
        search: true
      },
      {
        "label": "负责人",
        "prop": "personInCharge",
        "align": "center",
        "width": 150,
        "overHidden": true,
        maxlength: 10,
        rules: [
          {
            required: true,
            message: '负责人不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, cb: Function) => {
              if (!/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(value)) {
                cb(new Error('负责人名称只能输入中文、字母、数字'))
                return
              }
              cb()
            },
            trigger: 'blur'
          }
        ],
        span: 12
      },
      {
        "label": "手机号",
        "prop": "phone",
        "align": "center",
        "width": 150,
        maxlength: 11,
        editSlot: true,
        span: 12,
        rules: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          },
          {
            validator: checkPhone,
            trigger: 'blur'
          }
        ],
      },
      {
        label: '合作代理商数量',
        prop: 'agentQuantity',
        align: 'center',
        width: 150,
        slot: true,
        addHide: true,
        editHide: true
      },
      {
        label: '合作设备数量',
        prop: 'deviceNum',
        align: 'center',
        width: 150,
        slot: true,
        addHide: true,
        editHide: true
      },
      {
        "label": "状态",
        "prop": "status",
        "align": "center",
        "width": 100,
        "type": "select",
        "search": true,
        "slot": true,
        dicData: [
          {
            label: '启用',
            value: 1
          },
          {
            label: '禁用',
            value: 2
          }
        ],
        addHide: true,
        editHide: true
      },
      {
        "label": "备注",
        "prop": "remarks",
        "align": "center",
        "type": "textarea",
        "overHidden": true,
        maxlength: 100,
        showWordLimit: true
      },
      {
        "label": "创建时间",
        "prop": "addTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true,
        addHide: true,
        editHide: true
      },
    ]
  }

  agentDialog = false

  agentTableLoading = false

  agentModelForm: any = {}

  agentTableData: any = []

  agentTableTotal = 0

  agentQueryParam: any = {
    partnerId: '',
    page: '',
    limit: 10
  }

  agentCrudOption: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: '代理商编号',
        prop: 'agentId',
        align: 'center'
      },
      {
        label: '代理商名称',
        prop: 'agentName',
        align: 'center'
      },
      {
        label: '负责人',
        prop: 'agentPersonInCharge',
        align: 'center'
      },
      {
        label: '手机号',
        prop: 'agentPhone',
        align: 'center'
      }
    ]
  }


  deviceDialog = false

  deviceTableLoading = false

  deviceModelForm: any = {}

  deviceTableData: any = []

  deviceTableTotal = 0

  deviceQueryParam: any = {
    partnerId: '',
    page: '',
    limit: 10
  }

  deviceCrudOption: ICrudOption = {
    searchBox: false,
    menu: false,
    column: [
      {
        label: '设备编号',
        prop: 'deviceSn',
        align: 'center'
      },
      {
        label: '所属代理商',
        prop: 'agentName',
        align: 'center'
      },
      {
        label: '消费单价(元)',
        prop: 'price',
        align: 'center'
      },
      {
        label: '业务合作方分成比例',
        prop: 'commission',
        align: 'center',
        slot: true
      },
      {
        label: '投放地址',
        prop: 'address',
        align: 'center',
        slot: true
      },
      {
        label: '状态',
        prop: 'deviceStatus',
        align: 'center',
        slot: true
      }
    ]
  }

  getList () {
    this.tableLoading = true

    const query: ICooperatingParams = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length == 2) {
      query.addTimeStart = query.addTime[0]
      query.addTimeEnd = query.addTime[1]
    } else {
      query.addTimeStart = ''
      query.addTimeEnd = ''
    }
    delete query.addTime

    cooperatingPageApi(query).then(e => {
      if (e && e.data) {
        this.tableLoading = false
        this.tableData = e.data.list
        this.tableTotal = e.data.total
      }
    })
  }

  rowSave (form: ICooperating, done: Function, loading: Function) {
    const data = deepCopy(form)
    delete data.addTime
    cooperatingCreateApi(data).then(e => {
      if (e && e.data) {
        this.getList()
        this.$message.success('操作成功!')
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: ICooperating, done: Function, loading: Function) {
    const data = deepCopy(form)
    delete data.addTime
    cooperatingModifyApi(data).then(e => {
      if (e && e.data) {
        this.getList()
        this.$message.success('操作成功!')
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  openView (row: ICooperating, index: number) {
    cooperatingDetailApi(row.id).then(e => {
      if (e && e.data) {
        // @ts-ignore
        this.$refs.crudRef.rowView(e.data, index)
      }
    })
  }

  openEdit (row: ICooperating, index: number) {
    cooperatingDetailApi(row.id).then(e => {
      if (e && e.data) {
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e.data, index)
      }
    })
  }

  switchStatus (id: string, status: number) {
    cooperatingModifyApi({id, status}).then(e => {
      if (e && e.data) {
        this.getList()
        this.$message.success('操作成功!')
      }
    })
  }

  openAgentDialog(row: ICooperating) {
    this.agentQueryParam.partnerId = row.id
    this.agentQueryParam.page = 1
    this.agentQueryParam.limit = 10
    this.getAgentList()
    this.agentDialog = true
  }

  getAgentList () {
    this.agentTableLoading = true
    cooperatingAgentPageApi(this.agentQueryParam).then(e => {
      if (e && e.data) {
        this.agentTableLoading = false
        this.agentTableData = e.data.list
        this.agentTableTotal = e.data.total
      }
    })
  }

  openDeviceDialog (row: ICooperating) {
    this.deviceQueryParam.partnerId = row.id
    this.deviceQueryParam.page = 1
    this.deviceQueryParam.limit = 10
    this.getDeviceList()
    this.deviceDialog = true
  }

  getDeviceList () {
    this.deviceTableLoading = true
    cooperatingDevicePageApi(this.deviceQueryParam).then(e => {
      if (e && e.data) {
        this.deviceTableLoading = false
        this.deviceTableData = e.data.list
        this.deviceTableTotal = e.data.total
      }
    })
  }

  getStatusText(status: number) {
    if (status === 1) {
      return '待分配'
    }
    if (status === 2) {
      return '待激活'
    }
    if (status === 3) {
      return '已激活'
    }
    if (status === 4) {
      return '暂停使用'
    }
  }

  created () {
    this.getList()
  }
}
